import styled from 'styled-components';

type backgroundProps = {
  zIndexOverlay?: number;
}

export const ModalContent = styled.div`
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @keyframes fadeIn {
    0%   { opacity: 0; }
    100% { opacity: 1; }
  }

  .modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1050;
    animation: fadeIn 500ms;

    &__backdrop{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: black;
      opacity: 0.7;
    }
    &__content{
      position: absolute;
      top: 50%;
      left: 50%;
      width: 90%;
      transform: translate(-50%, -50%);
      display: block;
      text-align: center;
      height: 60vh;
      max-width: 1100px;

      @media (min-width: 768px) {
        height: 533px;
        max-width: 1100px;
      }

      &__close_button {
        text-align: end;
        color: white;
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 10px;
        cursor: pointer;
      }
    }
  }
`

export const Background = styled.div`
  background-color: rgba(0, 0, 0, .4);
  height: 100%;
  opacity: 0.8;
  position: fixed;
  width: 100%;
  z-index: ${(props: backgroundProps) => props.zIndexOverlay ? props.zIndexOverlay : '-1' };
`
